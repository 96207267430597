export const environment = {
  production: true,
  linkdInUr_AccessToken:'https://www.linkedin.com/oauth/v2/',
  linkdInApi:'https://api.linkedin.com/v2/',
  firebase: {
  },
  debug: false,
  log: {
    auth: false,
    store: false,
  },

  smartadmin: {
    api: null,
    db: 'smartadmin-angular'
  },
  API_END_POINT_ADMIN : 'https://fkbwl.api.io-market.net/api/v1/',
  API_END_POINT_BUSINESS : 'https://api-business.io-procurement.com/api/v1/',
  API_END_POINT_ACCOUNT : 'https://fjy29.api.io-market.net/api/v1/',
  LOGIN_URL:"https://accounts.io-market.net/#/auth/login/",
  ADMIN_URL:"https://admin.io-market.net/#/",
  allowedRedirectPath:['business.io-market.net','contract.netgate.io-market.net','gate2b.io-market.net','fkbwl.api.io-market.net','dhdgu.api.io-market.net','fjy29.api.io-market.net','proffix.io-market.net','pxbkd.api.io-market.net','accounting.io-market.net','acbkd.api.io-market.net','crm.io-market.net'],
  profile_URL :"https://accounts.io-market.net/#/profile",
  privateKey:{key:"MIIEpAIBAAKCAQEAx5DMt1FGNFvMerMldZeh7o8iPdSrknRV6kCJfTfA60lMsJtFRbgwmED6jdpXoYG+Zh+Lt9nsCm8Mkj2c8ZFa3Gx3ANVZAJqcz7ztR/HFxVw2VIDEbbpQuVfbbf9O1W4WzOgvuWidPNNXuY62WRNnE/H4MkYVE8Bh+dN/c3VQhGycbvmuYjV6cG/EQv21vrJkCn0dMAx/wzo9ZQyxcsU0jUEOJczbnQyr4UoYsWtNQIXpZTQStRR3pbvNeyP2Ab7zo1ridahE+UkETQclaif80fGZ/3DcMpCR1SD0mryUb8z3pAwrygzuORRjs0augbvhAZg2WjVvfWbVepgF3KBlbwIDAQABAoIBAHab6gAeLvKZiMt4RBBC4fxuV7SV/K8bs6B5YP3qMB/nPcxNTHumgENNg1zh42lbOnVp1FmeDLsay0bzlZ7BfFY7pPL62l4Qhpi1OfWjjZ7CMoxAx/5LWQT/YaxbrFgPiT+Z1BuHQMTQ+3eOiXQhowborlH68zX9Bn48JKjTldpNwg2YZvPeZidWGKhqkeQIgj/wsM0fGUl5aR+xqTRt5ZOydjITDXKFQh6Ms+lhW+w73AaKfVZ16VVWrBCXqIjnVM6HUuzSv2SeJmlHZetw9uvvHBDZf0kYzC3DLpTtyqLacoplKc3kxQiV/vJuiwubVgLqHDeEawqEH7jWjKJa1dECgYEA7ptjGuYk1l0H/fWQn0BlPRVZO/l37bbkzyYJZbRh2SAxYgeh+EluXviRXDKwcRSkCnyNSM6cDf33f5U9pw46mCCXZPvfoFaIRJ70SF/2CMtiXyiw+o1ykEBKr12LNiK829+NrAQph9CGaAhm5AmGDsjDN20192Kje2HJ4z5YX7cCgYEA1hzd9HVA9EYCylPzc4BeeZ191ltd053p2a8PVJ2k2cToOcnBz+81MOfPKiyNzfVEdgSS95yCjHRP0CkViVsp/UpVHhVhRYgDU5nskKMfHvu1VG6RPjnWBhzWIwJrm+E6yxl0/NMuLkqQk14tl2NhQqq3kgs/D0TuJks2o6QTOAkCgYBCJWF7+4EKzA+DH6hP/ZITNO/f6HCOvb0AkBLa1v6pKnuGKYeFRRYLzey+Gm8/afFv1sdz/5LTbixGeiD1RsSHl8FghVKhKFvuagJebfvOxFMo/ibRzpSL2MbbhpspDsg8S+UCYsUAmZYpEOs1HE7FmnJD00q6oeWgNqO5E3d8UQKBgQCNx72986rYVkfgvKiYEif9NimuuP+vcoD4sbBgIqeXwIjaQ1yAmXDKeqW20pvwBvqXf73mExBre1WijKIDO4fZftBQRG4OAdvaDzIUMMAZ3ymayECLSPcNQykVM7QYG5O4C5ppFFofMyb7PsDH33n3EF7QaXwPW2CroOCEidBkKQKBgQDnF4bqesdBbtlET7ZnxDtaCJw/kZg1SFdeJWDUCNDOKmMu5zCdoisDFjvt5qLIIPPDPiwxf0hQXGJl76afE9rz03IYC98DtjrKxNmJzH8832PcZgDE4Z//8Bjn4ht+WZN7zcMcpXftrMKVtMLkQCSeBOMaBz4qUj3FcgqOVSKMSA=="},
  encryptionKey : "RdAO2InTo6jl4ArLYyIInQ==",
  cookieDomain:".io-market.net",
  checkCookie:"expireCookie",
  tokenExpireTime:"cookieCreatedTime",

   //LinkdIn
   client_id:"7750r8oy6j8tjb",
  scope:"r_liteprofile%20r_emailaddress",
  redirect_uri:"https://accounts.io-market.net/#/auth/socialLogin",
  grant_type:"authorization_code",
  client_secret:"y9ZnPaT08NlvyKT8",


  // Google
  gClient_id:'28645529457-vlfk1ejnbj2837hftfp8o5fvv53thjvt.apps.googleusercontent.com',
  cookiepolicy: 'single_host_origin',
  gScope:'profile email',
  stripePK:"pk_test_51JPR33HCmdVWPntTtDn8z71SB3EJOhgvYMM33olpRJi1qtNW8T79BfFT63UT0C9esWVkANaEaLWB8ca15inthRN800MTvxgTol",
  LOGOUT_URL :"https://mtc.io-market.net/#/auth/logout/",
  domain:'.io-market.net',
  languageCookie:'languageCookie',
  styleCookie:'styleCookie',
  ACCOUNTS_URL:'https://accounts.io-market.net/#/',
  WC_APPS:"https://wc.io-market.net/subscribedApps/main.js",
  WC_PROFILE:"https://wc.io-market.net/profile/main.js",
  USER_ACTIVE_CHECK:"isUserActive",
  userInfo:"userInfo",
  BEXIO_URL: "https://bexio.io-market.net/#/",
  GATE2BURL:"https://gate2b.io-market.net/",
  API_END_POINT_GATE2B:"https://dhdgu.api.io-market.net/api/v1/gate2bAPI/gate2B",
  MTCURL: "https://mtc.io-market.net/#/",
  API_END_POINT_MTC : 'https://mqzpl.api.io-market.net/api/v1/gate2bAPI',
  mtc_cookie: "mtc_cookie",
  permissions: 'permissions',
  EBILL_MAIL:"ebillgateway@io-market.com",
  API_SIX_END_POINT: 'https://mqzpl.api.io-market.net/api/v1/sixAPICertificate',
  FORMATS:{'CXMLWithPDF':'.xml', "CXML1.2":'.xml', 'ETS3.1':'.xml', 'SAP02' :'.xml', 'OpenTrans2.1':'.xml','EDIFACTD.01B':'.edi','YellowBill2.0':'.xml','SignedYellowBill2.0':'.xml','Comarch1.0':'.xml','ETS2.0':'.xml','SAP05':'.xml','QR PDF':'.pdf','Meta Coded PDF':'.pdf' },
  EBILL_QR_INVOICE: "D_EBILL_QR_PDF_SPEZIFIKATION_DE_2022.PDF",
  color:"colorChangeTest",
  XSD_API_HOST:"https://mqzpl.api.io-market.net/api/v1/gate2bAPI/",
  API_NODE_END_POINT:'https://mqzpl.api.io-market.net/api/v1/',
  tax:"0.081",
  fileGrabInterface:[{label: 'SFTP', value:'sftp'},{label: 'EMAIL', value:'email'},{label:'WEB SERVICE', value: 'ws'},{label:'AS2', value: 'as2'},{label:'OTHER', value: 'other'}],
  EBILL_ERROR_PRICE:"0.20",
  fileCompareUrl: "http://192.168.55.237:5005/file-compare.html",
  COMPARE_API: "https://dwncu.api.io-market.net/api/v1/main/",
  posfinance_id:"41100000007797776",
  interconnect_posfinance_receive_iomID:"41100000000023711"
};
