import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { MainLayoutComponent } from "./shared/layout/app-layouts/main-layout.component";
import { AuthLayoutComponent } from "./shared/layout/app-layouts/auth-layout.component";
import { AuthGuard } from "./core/guards/auth.guard";
import { RoleResolveService } from "./core/resolver/role-resolve.service";

const routes: Routes = [
  {
		path: "", redirectTo: 'auth', pathMatch: 'full',
	},
  {
    path: "auth",
    component: AuthLayoutComponent,
    loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: "",
    component: MainLayoutComponent,
    data: { pageTitle:"" },
    children: [
      // { 
      //   path: 'companyManagement',
      //   loadChildren: () => import('./features/company-management/company-management.module').then(m => m.CompanyManagementModule),
      //   data: { pageTitle: "account_companyManagemet", right: 'mtc.company.' },
      //   canActivate: [AuthGuard],
      //   // resolve:{
      //   //   companyManagement: RoleResolveService
      //   // }
      // },
      // {
      //   path: "invoice",
      //   loadChildren: () => import('./features/admin-invoice/admin-invoice.module').then(m => m.AdminInvoiceModule),
      //   data: { pageTitle: "subscription_plans_and_pricing", right: 'mtc.invoice.' },
      //   canActivate: [AuthGuard],
      // },
      {
        path: 'company', 
        loadChildren: () => import('@app/features/company/company.module').then(m => m.CompanyModule),
        data: { pageTitle: "ebill", right: 'mtc.company.' },
        canActivate: [AuthGuard]

      },
      {
        path: "transactions",
        loadChildren: () => import('./features/transactions/transactions.module').then(m => m.TransactionsModule),
        data: { pageTitle: "transactions", right: 'mtc.transactions.' },
        canActivate: [AuthGuard],
      },
      // {
      //   path: "subscription",
      //   loadChildren: () => import('./features/subscriptions/subscriptions.module').then(m => m.SubscriptionsModule),
      //   data: { pageTitle: "subscriptions", right: 'mtc.subscription.' },
      //   canActivate: [AuthGuard],
      // },
      // {
      //   path: 'pdf2xml', 
      //   loadChildren: () => import('@app/features/pdf2xml/pdf2xml.module').then(m => m.Pdf2XmlModule),
      //   data: { pageTitle: "pdf2xml", right: 'mtc.pdf2xml.' },
      //   canActivate: [AuthGuard]

      // },    
      {
        path: 'ebill', 
        loadChildren: () => import('@app/features/ebill/ebill.module').then(m => m.EbillModule),
        data: { pageTitle: "ebill", right: 'mtc.ebill.' },
        canActivate: [AuthGuard]

      },
      {
        path: 'edi', 
        loadChildren: () => import('@app/features/edi/edi.module').then(m => m.EdiModule),
        data: { pageTitle: "ebill", right: 'mtc.company.' },
        canActivate: [AuthGuard]

      },
      {
        path: "commision",
        loadChildren: () => import('./features/agent/agent.module').then(m => m.AgentModule),
        data: { pageTitle: "commision", right: 'mtc.agent.' },
        canActivate: [AuthGuard],
      },
      
      {
        path: "pdftoxml",
        loadChildren: () => import('@app/features/pdftoxml/pdftoxml.module').then(m => m.PdfToXmlModule),
        data: { pageTitle: "pdf2xml", right: 'mtc.subscription.' },
        canActivate: [AuthGuard],
      },
      {
        path: "usermanagement",
        loadChildren: () => import('./features/user-management/user-management.module').then(m => m.UserManagementModule),
        data: { pageTitle: "usermanagement", right: 'mtc.user.' },
        canActivate: [AuthGuard],
      },
     
      {
        path: 'bulkimport', 
        loadChildren: () => import('@app/features/bulkimport/bulkimport.module').then(m => m.BulkImportModule),
        // loadChildren: () => import('@app/features/bulkimport/bul
        data: { pageTitle: "bulk-important" ,right: 'mtc.subscription.'},
        canActivate: [AuthGuard]

      },
      {
        path: 'logging', 
        loadChildren: () => import('@app/features/logging/logging.module').then(m => m.LoggingModule),
        // loadChildren: () => import('@app/features/bulkimport/bul
        data: { pageTitle: "logging" ,right: 'mtc.subscription.'},
        canActivate: [AuthGuard]

      },
      {
        path: 'email', 
        loadChildren: () => import('@app/features/email/email.module').then(m => m.EmailModule),
        // loadChildren: () => import('@app/features/bulkimport/bul
        data: { pageTitle: "email" ,right: 'mtc.subscription.'},
        canActivate: [AuthGuard]

      },
      {
        path: 'company-name-check', 
        loadChildren: () => import('@app/features/company-name-check/company-name-check.module').then(m => m.CompanyNameCheckModule),
        // loadChildren: () => import('@app/features/bulkimport/bul
        data: { pageTitle: "email" ,right: 'mtc.subscription.'},
        canActivate: [AuthGuard]

      },
      {
        path: 'print', 
        loadChildren: () => import('@app/features/print/print.module').then(m => m.PrintModule),
        // loadChildren: () => import('@app/features/bulkimport/bul
        data: { pageTitle: "email" ,right: 'mtc.subscription.'},
        canActivate: [AuthGuard]

      },
      { path: "**", redirectTo: "company"}
    ]
  },
  { path: "**", redirectTo: "auth"}
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy'})],
  exports: [RouterModule],
  providers: [RoleResolveService]
})
export class AppRoutingModule {}
